<template>
    <div class="eCommerce-container">
        <div class="btn-box">
            <el-button class="button-link" type="primary" plain>
                <a :href="downloadHref">下载模板</a>
            </el-button>
            <el-button type="primary" @click="importBtn" plain>导入模板</el-button>
            <input type="file" ref="excelInput" accept=".xls, .xlsx" title="" @change="changeExcel($event)" style="display: none" />
            <el-button type="primary" @click="addBtn" plain style="margin-left: 10px">添加客户数据</el-button>
        </div>
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="nick_name" label="客户名称" align="center"></el-table-column>
            <!-- <el-table-column prop="buy_cycle" label="购买周期（天）" align="center"></el-table-column> -->
            <el-table-column prop="avg_price" label="平均客单价（元）" align="center"></el-table-column>
            <el-table-column prop="praise_rate" label="星级评价" align="center"></el-table-column>
            <el-table-column prop="stand_time" label="页面停留时间" align="center"></el-table-column>
            <el-table-column prop="buy_num" label="交易笔数" align="center"></el-table-column>
            <el-table-column prop="change_num" label="订单改价次数" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex; flex-direction: column; align-items: center">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="" />
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <!--        :hide-on-single-page="true"-->
        <el-pagination
            class="pages-center"
            style="margin: 20px 0 30px"
            v-if="infoList.length > 0"
            :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="listPages.total"
            @current-change="pagesCurrentChange"
        >
        </el-pagination>

        <el-dialog
            title="添加客户数据"
            :visible.sync="dialogVisible"
            width="500px"
            custom-class="custom-dialog"
            :close-on-click-modal="false"
            @opened="openedDialog"
            @close="closeDialog"
        >
            <el-form :model="addForm" :rules="rules" ref="addForm" label-width="137px" class="demo-ruleForm">
                <el-form-item label="客户名称" prop="nick_name">
                    <el-input v-model="addForm.nick_name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="客户姓名" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item> -->
                <el-form-item label="性别" prop="sex">
                    <el-select v-model="addForm.sex" placeholder="请选择性别" style="width: 100%" clearable>
                        <el-option v-for="item in sexList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年龄" prop="age">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.age"
                        :precision="0"
                        :controls="false"
                        :min="0"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input
                        v-model="addForm.phone"
                        auto-complete="new-password"
                        placeholder="01088888888或0955-7777777或18888888888"
                    ></el-input>
                </el-form-item>
                <el-form-item label="收货地址" prop="address">
                    <el-input
                        type="textarea"
                        auto-complete="new-password"
                        v-model="addForm.address"
                        style="width: 100%"
                        resize="none"
                    ></el-input>
                </el-form-item>

                <!-- <el-form-item label="购买周期(天)" prop="buy_cycle">
                    <el-input-number class="number-input" v-model="addForm.buy_cycle" :precision="0" :controls="false" :min="0" style="width: 100%" placeholder="交易笔数小于2笔客户的不需要填写"></el-input-number>
                </el-form-item> -->
                <el-form-item label="交易笔数" prop="buy_num">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.buy_num"
                        :precision="0"
                        :controls="false"
                        :min="0"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="平均客单价(元)" prop="avg_price">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.avg_price"
                        :precision="2"
                        :controls="false"
                        :min="0"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="星级评价" prop="praise_rate">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.praise_rate"
                        :precision="2"
                        :controls="false"
                        :min="0.0"
                        :max="100.0"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="页面停留时间(S)" prop="stand_time">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.stand_time"
                        :precision="2"
                        :controls="false"
                        :min="0"
                        style="width: 100%"
                        placeholder="保留2位小数"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="订单改价次数" prop="change_num">
                    <el-input-number
                        class="number-input"
                        v-model="addForm.change_num"
                        :precision="0"
                        :controls="false"
                        :min="0"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    stuOpCustomerInfoList,
    stuOpSaveCustomerInfo,
    stuOpCustomerInfoDetail,
    stuOpDelCustomerInfo,
    stuOpGetCustomerInfoExcel,
} from "@/utils/apis.js";
export default {
    data() {
        let checkMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入联系电话"));
            }
            if (/(^1\d{10}$)|(^0\d{2,3}-?\d{7,8}$)/.test(value) === false) {
                callback(new Error("请输入正确的手机格式"));
            } else {
                callback();
            }
        };
        return {
            op_id: Number(this.$route.query.op_id) || null,
            course_id: Number(this.$route.query.course_id) || null,
            infoList: [],
            downloadHref: "",
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            sexList: [
                {
                    key: 1,
                    value: "男",
                },
                {
                    key: 2,
                    value: "女",
                },
            ],
            // 弹窗
            dialogVisible: false,
            addForm: {
                id: null,
                nick_name: "",
                name: "",
                sex: void 0,
                age: void 0,
                phone: "",
                address: "",
                buy_cycle: void 0,
                buy_num: void 0,
                avg_price: void 0,
                praise_rate: void 0,
                stand_time: void 0,
                change_num: void 0,
            },
            rules: {
                nick_name: { required: true, message: "请输入客户名称", trigger: "blur" },
                // name: { required: true, message: '请输入客户姓名', trigger: 'blur' },
                sex: { required: true, message: '请选择性别', trigger: 'change' },
                age: { required: true, message: "请输入年龄", trigger: "blur" },
                phone: { required: true, validator: checkMobile },
                address: { required: true, message: "请输入收货地址", trigger: "blur" },
                buy_num: { required: true, message: "请输入交易笔数", trigger: "blur" },
                avg_price: { required: true, message: "请输入平均客单价(元)", trigger: "blur" },
                praise_rate: { required: true, message: "请输入好评率", trigger: "blur" },
                stand_time: { required: true, message: "请输入页面停留时间(秒)", trigger: "blur" },
                change_num: { required: true, message: "请输入订单改价次数", trigger: "blur" },
            },
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        // 获取列表
        getList() {
            let params = {
                page: this.listPages.currentPageNum,
                limit: this.listPages.eachPageNum,
            };
            if (this.op_id) {
                params.op_id = this.op_id;
                params.course_id = this.course_id;
            }
            stuOpCustomerInfoList(params).then((res) => {
                this.downloadHref = res.data.download;
                this.infoList = res.data.list;
                this.listPages.total = res.data.total;
                if (this.listPages.total !== 0 && this.infoList.length === 0) {
                    this.listPages.currentPageNum--;
                    this.getList();
                }
            });
        },
        // 切换分页
        pagesCurrentChange(val) {
            this.listPages.currentPageNum = val;
            this.getList();
        },
        // 编辑
        editBtn(id) {
            let params = {
                id: id,
            };
            if (this.op_id) {
                params.op_id = this.op_id;
                params.course_id = this.course_id;
            }
            stuOpCustomerInfoDetail(params).then((res) => {
                this.addForm = {
                    id: res.data.id,
                    nick_name: res.data.nick_name,
                    name: res.data.name,
                    sex: res.data.sex,
                    age: res.data.age || void 0,
                    phone: res.data.phone,
                    address: res.data.address,
                    buy_cycle: res.data.buy_cycle || void 0,
                    buy_num: res.data.buy_num || void 0,
                    avg_price: res.data.avg_price || void 0,
                    praise_rate: res.data.praise_rate || void 0,
                    stand_time: res.data.stand_time || void 0,
                    change_num: res.data.change_num || void 0,
                };
                this.dialogVisible = true;
            });
        },
        // 删除
        delBtn(id) {
            this.$confirm("是否删除该客户信息，删除将无法恢复", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "green-message-box",
                type: "warning",
            })
                .then(() => {
                    let params = {
                        id: id,
                    };
                    if (this.op_id) {
                        params.op_id = this.op_id;
                        params.course_id = this.course_id;
                    }
                    stuOpDelCustomerInfo(params).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("已取消删除");
                });
        },
        // 添加
        addBtn() {
            this.dialogVisible = true;
        },
        // 导入
        importBtn() {
            this.$refs.excelInput.click();
        },
        // 选择文件
        changeExcel(event) {
            if (event.target.files.length === 0) return;
            // if (event.target.files[0].size > 1024 * 1024 * 200) {
            //     this.$message.warning('文件大小不得超过200M');
            //     return;
            // }
            let fileList = event.target.files[0];
            let formData = new FormData();
            formData.append("excel", fileList);
            if (this.op_id) {
                formData.append("op_id", this.op_id);
                formData.append("course_id", this.course_id);
            }
            stuOpGetCustomerInfoExcel(formData)
                .then((res) => {
                    this.$message.success(res.msg);
                    this.listPages.currentPageNum = 1;
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.$refs.excelInput.value = "";
                });
        },

        // 打开弹窗
        openedDialog() {},
        // 关闭弹窗
        closeDialog() {
            this.resetForm();
        },
        // 弹窗内保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formData = new FormData();
                    formData.append("nick_name", this.addForm.nick_name);
                    formData.append("name", this.addForm.name);
                    formData.append("sex", this.addForm.sex);
                    formData.append("age", this.addForm.age);
                    formData.append("phone", this.addForm.phone);
                    formData.append("address", this.addForm.address);
                    formData.append("buy_num", this.addForm.buy_num);
                    formData.append("avg_price", this.addForm.avg_price);
                    formData.append("praise_rate", this.addForm.praise_rate);
                    formData.append("stand_time", this.addForm.stand_time);
                    formData.append("change_num", this.addForm.change_num);
                    if (this.addForm.buy_cycle !== void 0) {
                        formData.append("buy_cycle", this.addForm.buy_cycle);
                    }
                    if (this.addForm.id) {
                        formData.append("id", this.addForm.id);
                    }
                    if (this.op_id) {
                        formData.append("op_id", this.op_id);
                        formData.append("course_id", this.course_id);
                    }
                    stuOpSaveCustomerInfo(formData).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                        this.dialogVisible = false;
                        this.resetForm();
                    });
                } else {
                    return false;
                }
            });
        },
        // 重置表单
        resetForm() {
            this.$refs.addForm.resetFields();
            this.addForm = {
                id: null,
                nick_name: "",
                name: "",
                age: void 0,
                phone: "",
                address: "",
                buy_cycle: void 0,
                buy_num: void 0,
                avg_price: void 0,
                praise_rate: void 0,
                stand_time: void 0,
                change_num: void 0,
            };
        },
    },
};
</script>

<style scoped lang="scss">
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.btn-box {
    margin-top: 20px;
    text-align: right;
    .button-link {
        padding: 0;
        a {
            display: block;
            padding: 12px 20px;
            transition: all 0s;
            color: inherit;
        }
    }
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
</style>
